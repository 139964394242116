<template>
  <div class="app-container">
  <a-spin :spinning="loading">
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">AI模型管理</span>
      </div>
      <a-button-group>
        <a-button style="margin-left:10px;" type="primary" v-hasPermi="['aimanage_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
      </a-button-group>
    </div> -->
    <div class="table-search">
       <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" v-show="showSearch" label-width="68px">
          <a-form-model-item label="模型名称" prop="name">
            <a-select v-model="queryParams.name" style="width:200px;" placeholder="请选择AI模型" allowClear show-search @change="changeModelName">
                <a-select-option v-for="item in modelList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
        </a-form-model-item>
          <a-form-model-item label="模型版本" prop="modelVersion">
            <a-select v-model="queryParams.modelVersion" style="width:200px;" placeholder="请选择模型版本" allowClear show-search>
                <a-select-option v-for="item in modelVersionList" :key="item.modelVersion" :value="item.modelVersion">{{ item.modelVersion }}</a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item>
            <a-button icon="search" type="primary"  @click="handleQuery">查询</a-button>
            <a-button icon="a-icon-refresh" @click="resetQuery" style="margin-left: 10px">重置</a-button>
        </a-form-model-item>
        <a-form-model-item style="float: right;margin-right: 0px;">
          <a-button style="margin-left:10px;" type="primary" v-hasPermi="['aimanage_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
        </a-form-model-item>
        </a-form-model>
    </div>
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
    <a-table rowKey="id" :columns="columns" :dataSource="manageList" :row-selection="rowSelectionConfig"
               ref="table" :pagination="{
        defaultCurrent: queryParams.pageNum, // 默认当前页数
        defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
        total: total, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['20', '50', '100'],
        showTotal: (total, range) => `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: onPageSizeChange,
        // 改变每页数量时更新显示
        onChange: onPageSizeChange,
      }" bordered>
        <template v-slot:action="record">
          <a href="javascript:;" @click="handleUpdate(record)" v-hasPermi="['aimanage_edit']" style="margin-right: 10px;"><a-icon type="form" /> 编辑</a>
          <a href="javascript:;" @click="handleDelete(record)" v-hasPermi="['aimanage_delete']"><a-icon type="delete" /> 删除</a>
        </template>
      </a-table>
    </div>
    <!-- 添加或修改AI模型管理对话框 -->
    <a-modal :title="title" v-model:visible="open" width="500px" append-to-body>
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="模型名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入模型名称" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="版本信息" prop="modelVersion">
          <a-input v-model="form.modelVersion" placeholder="请输入模型版本信息" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="remark">
          <a-input v-model="form.remark" type="textarea" placeholder="请输入内容" style="height: 125px;"/>
        </a-form-model-item>
      </a-form-model>
      <div slot="footer" class="dialog-footer">
        <a-button type="primary" @click="submitForm">确 定</a-button>
        <a-button @click="cancel">取 消</a-button>
      </div>
    </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {message, Modal} from 'ant-design-vue';
import { listManage, getManage, delManage, addManage, updateManage } from "@/api/intelligenele/manage";
import { optionNameList,optionModelVersionList } from "@/api/image/recognition";

export default {
  name: "Manage",
  data() {
    return {
        columns: [
        {
          title: '模型名称',
          dataIndex: 'name',
        },
        {
          title: '模型版本信息',
          dataIndex: 'modelVersion',
        },
        {
          title: '说明',
          dataIndex: 'remark',
        },
        {
          title: '操作',
          key: 'operation',
          width: '130px',
          align:'center',
          className: 'operation-cls',
          scopedSlots: {customRender: 'action'}
        }
      ],
      selectedKeys: [],
      totalData: {},
      // 遮罩层
      loading: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // AI模型管理表格数据
      manageList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      modelList: [],
      modelVersionList: [],
      // 表单校验
      rules: {
        name: [
          { required: true, message: "模型名称不能为空", trigger: ["change", "blur"] }
        ],
        modelVersion: [
          { required: true, message: "模型版本信息不能为空", trigger: ["change", "blur"]}
        ],
      }
    };
  },
  created() {
    this.getList();
    this.optionNameList();
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKeys = selectedRowKeys;
           this.multiple = !this.selectedKeys.length
        }
      }
    }
  },
  methods: {
    // 获取模型数据
    optionNameList() {
        optionNameList().then(res => {
            if(res.success && res.data) {
                this.modelList = res.data || [];
            }
        });
    },
    // 根据模型获取版本
    changeModelName(e) {
      this.queryParams = {
        ...this.queryParams,
        modelVersion: undefined,
      };
      this.getModelVersionList();
    },
    // 获取模型版本数据
    getModelVersionList() {
      optionModelVersionList({
        name: this.queryParams.name
      }).then(res => {
            if(res.success && res.data) {
                this.modelVersionList = res.data || [];
            }
        });
    },
    /** 查询AI模型管理列表 */
    getList() {
      this.loading = true;
      listManage(this.queryParams).then(response => {
        this.manageList = response.rows.map(ele => {
          this.totalData[ele.id] = ele.name;
          return ele;
        });
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      // const that = this;
      // this.$nextTick(() => {
      //   that.$refs["form"].resetFields();
      // });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.selectedKeys = [];
      this.totalData = [];
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加AI模型管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getManage(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "编辑AI模型管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          this.loading = true;
          const res = this.form.id ? await updateManage(this.form) : await addManage(this.form);
          this.loading = false;
          if(res.success) {
            message.success("操作成功");
            this.open = false;
            this.getList();
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids =  this.selectedKeys;
      if(row.id) {
        ids = [row.id];
      }
      const names = ids.map(ele => this.totalData[ele]);
      const that = this;
      Modal.confirm({
        title: '提示',
        content: '是否确认删除AI模型名称为【' + names + '】的数据项？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          delManage(ids).then(res => {
            if(res.success) {
              that.selectedKeys = [];
              that.multiple = true;
              that.getList();
              message.success("删除成功");
            }
          });
        }
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('/ele/intelligenele/manage/export', {
        ...this.queryParams
      }, `manage_${new Date().getTime()}.xlsx`)
    },
    onPageSizeChange(current, pageSize){
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.getList();
    },
  }
};
</script>

<style scoped>
  .ant-select, .ant-input{
    width: 350px!important;
}
</style>